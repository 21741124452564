


































































































































import { Component, Vue } from 'vue-property-decorator';
import RoundRobin from '@/lib/Round-robin';
import Player from '@/models/Player';
import Member from '@/models/Member';
import TournamentModel from '@/models/TournamentModel';
import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/database'

@Component({
  components: {
  },
})
export default class V2 extends Vue {
  public name = '';
  public tournamentName = '';

  public tournamentModel = new TournamentModel();
  
  public result = ['', '勝ち', '負け', 'CO'];

  public database = firebase.database();

  private CreateMacthList() {
    if(this.tournamentModel.NameList.length > 1) {
      const roundRobin = new RoundRobin();
      this.tournamentModel.MatchList 
        = roundRobin.Create(this.tournamentModel.NameList, this.tournamentModel.Lane);
    } else {
      this.tournamentModel.MatchList = new Array<Array<Player>>();
    }
  }

  public AddName(): void {
    if(this.name != '') {
      const member = new Member(this.name);
      this.tournamentModel.NameList.push(member);
      this.name = '';

      this.CreateMacthList();

      this.tournamentModel.SortedNameList = this.tournamentModel.NameList.concat();
    }
  }

  public RemoveName(index: number): void {
    this.tournamentModel.NameList.splice(index, 1);
    this.tournamentModel.SortedNameList = this.tournamentModel.NameList.concat();

    if(this.tournamentModel.NameList.length > 1) {
        this.CreateMacthList();
      } else {
        this.tournamentModel.MatchList = new Array<Array<Player>>();
      }
  }

  public SaveData(): void {
    if(this.tournamentName != '' && this.tournamentModel != null) {
      const userId = firebase.auth().currentUser?.uid;
      this.tournamentModel.TournamentType = 2;
      this.tournamentModel.TournamentName = this.tournamentName;

      this.database.ref('tournaments/' + userId).push(this.tournamentModel);
    }
  }

  public CalcPoint(): void {
    const calcArray: {[key: string]: number} = {};

    this.tournamentModel.NameList.forEach((element) => {
      calcArray[element.Name] = 0;
    });

    this.tournamentModel.MatchList.forEach((matchCard) => {
      matchCard.forEach((card) => {
        const player = card.Name;
        calcArray[player] += card.Point != undefined ? this.tournamentModel.PointSetting[card.Point] : 0;
      });
    });

    this.tournamentModel.NameList.forEach((element) => {
      const totalPpint = calcArray[element.Name];
      element.TotalPoint = totalPpint;
    });

    this.tournamentModel.NameList.sort(function(a: Member, b: Member) {
        return (a.TotalPoint > b.TotalPoint) ? -1 : 1;
    });
  }

  public ChangeLane() {
    const pointSetting = new Array<number>();

    for(let i = 0; i < this.tournamentModel.Lane + 1; i++) {
      pointSetting[i] = this.tournamentModel.Lane - i;
    }

    pointSetting[0] = 0;

    this.tournamentModel.PointSetting = pointSetting;

    this.CreateMacthList();
  }
}
